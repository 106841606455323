import request from "./http.js";


// 菜单列表
export const menulist = () => {
    return request({
        url: '/backend/menu/get_menu_list',
        method: 'post'
    })
}

// 添加编辑菜单
export const addmenu = (data) => {
    return request({
        url: '/backend/menu/add_menu',
        method: 'post',
        data
    })
}

// 查看菜单数据
export const menuDetail = data => {
    return request({
        url: '/backend/menu/edit_info',
        method: 'post',
        data
    })
}

// 删除菜单
export const delmenu = (data) => {
    return request({
        url: '/backend/menu/del_menu',
        method: 'post',
        data
    })
}

//  角色列表
export const roleList = data => {
    return request({
        url: '/backend/rbac/get_role_list',
        method: 'post',
        data
    })
}

//  获取角色权限
export const getrolepower = (data) => {
    return request({
        url: '/backend/menu/user_have_menu',
        method: 'post',
        data
    })
}

// 添加或者修改角色
export const addRole = data => {
    return request({
        url: '/backend/rbac/add_role',
        method: 'post',
        data
    })
}

// 角色详情
export const roleDetail = data => {
    return request({
        url:'/backend/rbac/edit_info',
        method: 'post',
        data
    })
}

// 角色删除
export const roleDelete = data => {
    return request({
        url:'/backend/rbac/del_role',
        method:'post',
        data
    })
}

// 角色分配权限
export const rolePrivileges = data => {
    return request({
        url: '/backend/rbac/authorizePost',
        method: 'post',
        data
    })
}

// 管理员列表 
export const adminList = data => {
    return request({
        url: '/backend/adminuser/user_list',
        method: 'post',
        data
    })
}

// 添加管理员
export const addAdmin = data => {
    return request({
        url: '/backend/adminuser/submit_user',
        method: 'post',
        data
    })
}

// 删除管理员
export const deleteAdmin = data => {
    return request({
        url: '/backend/adminuser/delete_user',
        method: 'post',
        data
    })
}

// 管理员详情
export const AdminDetail = data => {
    return request({
        url: '/backend/adminuser/user_info',
        method: 'post',
        data
    })
}

// 管理员操作日志列表
export const adminOptLog = data => {
    return request({
        url: '/backend/adminlog/get_adminlog_list',
        method: 'post',
        data
    })
}