<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">管理员 列表</h3>
    </div>
    <div class="box">
      <div class="btnall">
        <el-button
          @click="addAdminShow = true"
          size="medium"
          type="primary"
          plain
          >添加 <i class="el-icon-plus"></i
        ></el-button>
        <div style="margin: 10px">
          <p>
            <span style="font-size: 14px; margin-right: 5px">用户名:</span>
            <el-input
              style="width: 350px"
              size="small"
              v-model="form.user_login"
              placeholder="请输入用户名"
            ></el-input>
          </p>
          <p style="margin: 10px 0">
            <label for="phone" style="font-size: 14px; margin-right: 5px"
              >手机号:</label
            >
            <el-input
              id="phone"
              style="width: 350px"
              size="small"
              v-model="form.mobile"
              placeholder="请输入电话号码"
            ></el-input>
            <el-button size="small" style="margin-left: 10px" @click="shaixuan"
              >筛选</el-button
            >
          </p>
        </div>
      </div>
      <el-table
        border
        :data="list"
        :row-style="{ height: '30px', padding: '0' }"
      >
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column prop="user_login" label="名称"></el-table-column>
        <el-table-column prop="name" width="100" label="角色"></el-table-column>
        <el-table-column prop="mobile" label="电话"></el-table-column>
        <el-table-column prop="create_time" label="创建时间"></el-table-column>
        <el-table-column
          prop="last_login_time"
          label="最近登录"
        ></el-table-column>
        <el-table-column width="100" prop="user_status" label="状态">
          <div slot-scope="scope">
            <el-tag
              v-if="scope.row.user_status == 1"
              size="mini"
              effect="dark"
              type="success"
              >正常</el-tag
            >
            <el-tag
              v-else-if="scope.row.user_status == 2"
              size="mini"
              effect="dark"
              type="danger"
              >禁用</el-tag
            >
            <el-tag v-else size="mini" effect="dark" type="info">禁用</el-tag>
          </div>
        </el-table-column>
        <el-table-column label="操作">
          <div slot-scope="scope">
            <el-button
              @click="setadmin(scope.row)"
              size="mini"
              type="primary"
              round
              >编辑</el-button
            >
            <el-button
              @click="deladmin(scope.row.id)"
              size="mini"
              type="danger"
              round
              >删除</el-button
            >
			<el-button
			  @click="chongzhi(scope.row.id)"
			  size="mini"
			  type="danger"
			  round
			  >重置密码</el-button>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin: 10px"
        @size-change="SizeChange"
        @current-change="CurrentChange"
        :current-page="form.page"
        :page-sizes="[5, 10, 20]"
        :page-size="form.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 添加管理员 -->
    <el-dialog title="添加管理员" :visible.sync="addAdminShow" width="28%">
      <el-form ref="addform" :rules="rules" :model="addform" label-width="80px">
        <el-form-item prop="mobile" label="手机:">
          <el-input
            style="width: 300px"
            size="mini"
            v-model="addform.mobile"
          ></el-input>
        </el-form-item>

        <el-form-item prop="user_login" label="用户名:">
          <el-input
            style="width: 350px"
            size="mini"
            v-model="addform.user_login"
          ></el-input>
        </el-form-item>
        <el-form-item prop="user_pass" label="密码:">
          <el-input
            style="width: 350px"
            size="mini"
            v-model="addform.user_pass"
            type="password"
            :placeholder="psdtxt"
          ></el-input>
        </el-form-item>
        <el-form-item prop="user_email" label="邮箱:">
          <el-input
            style="width: 350px"
            size="mini"
            v-model="addform.user_email"
          ></el-input>
        </el-form-item>
        <el-form-item width="100" prop="user_status" label="状态:">
          <el-radio v-model="addform.user_status" :label="1">正常</el-radio>
          <el-radio v-model="addform.user_status" :label="0">禁用</el-radio>
          <!-- <el-radio v-model="addform.user_status" :label="2">禁用</el-radio> -->
        </el-form-item>
        <el-form-item prop="role_id" label="角色名称:">
          <el-cascader
            size="mini"
            :placeholder="cascadertext"
            :props="optionProps"
            :options="rolelist"
            @change="setclass"
            ref="cascader"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addAdminShow = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  adminList,
  addAdmin,
  roleList,
  deleteAdmin,
  AdminDetail,
  
} from "@/api/irm.js";
import {reset_pwd} from "@/api/api.js";
export default {
  components: {},
  name: "",
  data() {
    return {
      form: {
        user_login: "",
        mobile: "",
        limit: 10,
        page: 1,
      },
      addAdminShow: false,
      list: [],
      rolelist: [],
      cascadertext: "请选择角色",
      addform: {
        user_pass: "", // 密码
        user_email: "", // 邮箱
        user_login: "", // 用户名
        mobile: "", // 手机
        role_id: "", // 角色id
        user_status: 1, // 用户状态 0禁用 1正常 2 未验证
      },
      rules: {
        name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
      },
      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      },
      psdtxt: "请输入密码!",
      total: 10,
    };
  },
  watch: {
    addAdminShow: function (newdata, olddata) {
      if (!newdata) {
        this.addform.user_pass = "";
        this.addform.user_email = "";
        this.addform.user_login = "";
        this.addform.mobile = "";
        this.addform.role_id = "";
        this.addform.user_status = 1;
        delete this.addform.id;
        this.cascadertext = '请选择角色'
      }
    },
  },
  methods: {
	chongzhi(id){
		this.reset_pwds(id)
	},
	async reset_pwds(id){
		const {data}=await reset_pwd({admin_id:id})
		if(data.code!=200){
			return  this.$message.error(data.data);
		}
		this.$message({
		  message: data.data,
		  type: 'success'
		});
	},
    SizeChange(e) {
      this.form.limit = e;
      this.getlist();
    },
    CurrentChange(e) {
      this.form.page = e;
      this.getlist();
    },
    shaixuan() {
      this.form.page = 1;
      this.form.limit = 10;
      this.getlist();
    },

    setadmin(datas) {
      // console.log(this.addform);
      this.cascadertext = datas.name;
      this.addform.role_id = datas.role_id;
      this.getadmindetail(datas.id);
    },

    async getadmindetail(id) {
      const { data } = await AdminDetail({ user_id: id });
      if (data.code != 200) return this.$message.error(data.data);
      console.log(data);
      this.addform.user_pass = data.data.user_pass;
      this.addform.user_email = data.data.user_email;
      this.addform.user_login = data.data.user_login;
      this.addform.mobile = data.data.mobile;
      this.addform.user_status = data.data.user_status;
      this.addform.id = data.data.id;
      this.addform.role_id = data.data.role_id[0];
      console.log(this.addform);
      this.addAdminShow = true;
    },

    deladmin(id) {
      this.$confirm("您真的要删除吗?, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await deleteAdmin({ id });
          if (data.code != 200) return this.$message.error(data.data);
          this.getlist();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    async getlist() {
      const { data } = await adminList(this.form);
      if (data.code != 200) return this.$message.error(data.data);
      this.list = data.data.data;
      this.total = data.data.total;
      console.log(this.list);
    },

    async submit() {
      const { data } = await addAdmin(this.addform);
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.getlist();
      this.addAdminShow = false;
    },

    async getrolelist() {
      const { data } = await roleList();
      if (data.code != 200) return this.$message.error(data.data);
      this.rolelist = data.data;
    },
    setclass() {
      let nodesObj = this.$refs["cascader"].getCheckedNodes();
      this.addform.role_id = nodesObj[0].data.id;
    },
  },
  created() {
    this.getlist();
    this.getrolelist();
  },
};
</script>

<style scoped lang='less'>
.btnall {
  padding: 10px 20px;
  margin: 20px 0;
  background-color: #f1f3f4;
  border-radius: 10px;
}
.el-table /deep/td {
  padding: 5px 0;
}
</style> 